<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        hide-selected
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="MatCatId"
        item-text="MatCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="MatCatFindCtrl"
        :clearable="!readonly"
        :multiple="multiple"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @update:search-input="UpdateSearchInputEvt"
        @blur="blurEvt">
    </v-autocomplete>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: [
      'value', 'label', 'matCatIdParent', 'showParentsOnly', 'showNoParentOnly', 
      'matCatToHide', 'multiple', 'readonly', 'smallChips', 'deletableChips',
      'selectOnReadonly'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      },
      matCatIdParent() {
        this.$refs.MatCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST_PAGINATED',
          NumRegsPag: 35,
          NumPag: 1,
          EmpId: this.empId,
          MatCatNom: valFind,
          MatCatIdParent: this.matCatIdParent,
          ShowParentsOnly: this.showParentsOnly,
          ShowNoParentOnly: this.showNoParentOnly,
          Searcher: true
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/matcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];

          const idsToHide = this.matCatToHide != null ? this.matCatToHide.map(m => m.MatCatId) : []; 
          result.data.EntsList.forEach(element => {
            if(idsToHide.indexOf(element.MatCatId) == -1) {
                var itemObject = {MatCatId: element.MatCatId, MatCatNom: element.MatCatNom}
                this.itemsList.push(itemObject);
            }
          });
          
          if (valorActual != null) {
            // if (valorActual.length == null) {
            if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.MatCatId != null) {
                  if (!this.itemsList.some(itm=>itm.MatCatId === valorActual.MatCatId)) {
                    this.itemsList.push(valorActual);
                  }
                }
            }
            else {
              valorActual.forEach(element => {  // es un array
                if (!this.itemsList.some(itm=>itm.MatCatId === element.MatCatId)) {
                  this.itemsList.push(element);
                }
              });
            }
          }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.MatCatFindCtrl.isMenuActive) {
            this.$refs.MatCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.MatCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.MatCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
        this.itemsList = [];
        this.loadItems();
    }
}
</script>