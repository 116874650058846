<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    material
                </div>

                <v-form
                  ref="form"
                  v-model="isValid"
                  autocomplete="off"
                >
                <v-tabs v-model="tab" background-color="transparent" show-arrows>
                    <v-tabs-slider color="blue"></v-tabs-slider>

                    <v-tab href="#tab-mat">Material</v-tab>
                    <v-tab href="#tab-cat">Categorías</v-tab>
                    <v-tab href="#tab-emp">Empresas</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :value="'tab-mat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <!-- FORMULARIO MATERIAL -->
                            <v-row>
                                <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="mat.MatCod"
                                        label="Código"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        required
                                        :rules="[v => !!v || 'El nombre es obligatorio']"
                                        v-model="mat.MatNom"
                                        label="Nombre *"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <v-checkbox
                                        class="mt-n3 mb-n7"
                                        v-model="mat.MatExi"
                                        label="Control de existencias"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="5" md="4" lg="3" xl="2">
                                    <MatVarFind 
                                        class="mb-n7" ref="matVar1Ctrl" 
                                        v-model="mat.MatVar1" 
                                        label="Variante 1" 
                                        :matVarIdParent="null"
                                        :showNoParentOnly="true"
                                    ></MatVarFind>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="5" md="4" lg="3" xl="2">
                                    <MatVarFind 
                                        class="mb-n7" ref="matVar2Ctrl" 
                                        v-model="mat.MatVar2" 
                                        label="Variante 2" 
                                        :matVarIdParent="null"
                                        :showNoParentOnly="true"
                                    ></MatVarFind>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <v-textarea
                                        class="mt-0 mb-n7"
                                        outlined
                                        auto-grow
                                        v-model="mat.MatObs"
                                        label="Observaciones"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-cat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- CATEGORIAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <!-- Botón añadir categoría -->
                                    <v-btn
                                        :loading="loading"
                                        :disabled="editMatCat"
                                        @click="onBtnNuevaCategoria"
                                        color="primary"
                                        small
                                        class="mb-2">
                                        Añadir categoría
                                    </v-btn>
                                  
                                  <v-data-table
                                      :headers="matCatHeaders"
                                      :items="matCatDataRows"
                                      :options.sync="matCatOptions"
                                      :footer-props="{itemsPerPageOptions: [100]}"
                                      :server-items-length="matCatTotalDataRows"
                                      :loading="matCatLoading"
                                      style="overflow: scroll"
                                      mobile-breakpoint="0"
                                      class="elevation-1"
                                      dense>
                                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                                      <template v-slot:item.Opt="{ item }">
                                        <v-btn
                                            color="error"
                                            class="mr-2 my-1"
                                            small
                                            @click.stop="onBtnShowMatCatDeleteModal(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <!-- NUEVA CATEGORÍA FORMULARIO -->
                                      <template v-if="editMatCat" slot="body.append">
                                          <tr class="new-row">
                                              <td>
                                                  <MatCatFind class="mb-n7" ref="matCatCtrl" v-model="newMatCat" :matCatToHide="matCatDataRows" label="Categoría"></MatCatFind>
                                              </td>
                                              <td style="min-width: 250px" align ="right">
                                                  <v-btn
                                                      :loading="matCatLoading"
                                                      :disabled="newMatCat == null"
                                                      @click="onBtnSaveCategoria"
                                                      color="primary"
                                                      class="mt-2 mb-4 mr-2"
                                                      small>
                                                      Aceptar
                                                    </v-btn>
                                                    
                                                  <v-btn
                                                      @click="onBtnCancelCategoria"
                                                      class="mt-2 mb-4 mr-2"
                                                      small>
                                                      Cancelar
                                                  </v-btn>
                                              </td>
                                          </tr>
                                      </template>
                                  </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-emp'" eager>
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- EMPRESAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <EmpresasRelacionadas
                                        ref="relatedEmps"
                                        :empList="empDataList" 
                                        :empTotal="empDataTotal" 
                                        :loading="loading" 
                                        @update-data="updateEmpData"
                                    ></EmpresasRelacionadas>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        to="/materiales"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Cancelar
                    </v-btn>

                    <!-- && canDelete" -->
                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Material -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR MATERIAL
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el material? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         
        <!-- Borrar Categoria -->
        <v-dialog
          v-model="showMatCatDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR CATEGORÍA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la categoría?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="matCatLoading"
                color="primary"
                @click="onBtnMatCatDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelMatCatDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
import MatCatFind from "../components/MatCatFind.vue";
import MatVarFind from "../components/MatVarFind.vue";
export default ({
    components: {
        EmpresasRelacionadas, MatCatFind, MatVarFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false, // Si el material esta presente en alguna solicitud no se podrá borrar
            showDeleteDialog: false,
            showMatCatDeleteDialog: false,
            showEmpDeleteDialog: false,
            mat: {
                MatId: 0,
                MatCod: '',
                MatNom: '',
                MatExi: false,
                MatVar1: {},
                MatVar2: {},
                MatObs: ''
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Tabla categorias
            matCatLoading: false,
            matCatTotalDataRows: 0,
            matCatDataRows: [],
            matCatOptions: {itemsPerPage: 100},
            matCatHeaders: [
                { text: 'Categorías relacionadas', width:300, sortable: false, value: 'MatCatNom' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición de categorias
            editMatCat: false, // Indica si se esta editando la nueva categoria
            newMatCat: {},
            selectedMatCat: null, // Indica la categoria seleccionada para el borrado
        }
    },
    // watch: {
      // mat: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.mat.MatId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/materiales');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_MAT',
            MatId: this.mat.MatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/mat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Mat;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  // canDelete
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el material.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.mat.MatVarId1 = this.mat.MatVar1 != null ? this.mat.MatVar1.MatVarId : null;
        this.mat.MatVarId2 = this.mat.MatVar2 != null ? this.mat.MatVar2.MatVarId : null;
        const matCatIds = this.matCatDataRows.map(m => m.MatCatId);
        const empIds = this.empDataList.map(m => m.EmpId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Mat: this.mat,
            MatCatIds: matCatIds,
            EmpIds: empIds
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/mat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el material.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            MatId: this.mat.MatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/mat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                item
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el material.');
            }
          });
        })
      },
      //#endregion
      
      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Material no encontrado.');
              this.goToList();
              return;
            }

            this.mat = data.item;
            // this.canDelete = data.canDelete;
            
            // Cargamos los datos de empresas y categorías
            this.empDataTotal = data.item.MatEmp.length;
            this.empDataList = [];
            data.item.MatEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
            this.matCatTotalDataRows = data.item.MatCatMat.length;
            this.matCatDataRows = [];
            data.item.MatCatMat.forEach(i => {
              this.matCatDataRows.push({
                MatCatId: i.MatCat.MatCatId,
                MatCatNom: i.MatCat.MatCatNom
              });
            });
          });
      },
      //#endregion

      //#region Añadir categorías
      onBtnNuevaCategoria() {
        this.editMatCat = true;
        this.newMatCat = null;
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.MatId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar el material.');
            }
          });
      },
      onBtnSaveCategoria() {
        if(this.newMatCat == null) { return; }
        this.matCatDataRows.push(this.newMatCat);
        this.matCatTotalDataRows += 1;
        this.newMatCat = null,
        this.editMatCat = false; 
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de categoría
      onBtnCancelCategoria() {
        this.editMatCat = false;
      },
      //#endregion

      //#region Eliminar categorías
      removeMatCat(item) {
        var index = this.matCatDataRows.map(m => m.MatCatId).indexOf(item.MatCatId);
        if (index != -1) {
          this.matCatDataRows.splice(index, 1);
          this.matCatTotalDataRows -= 1;  
        }
      },
      onBtnMatCatDelete() {
        this.removeMatCat(this.selectedMatCat);
        this.selectedMatCat = null;
        this.showMatCatDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowMatCatDeleteModal(item) {
        this.selectedMatCat = item;
        this.showMatCatDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelMatCatDelete() {
        this.selectedMatCat = null;
        this.showMatCatDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
        // if (this.mat == null) {
        //     this.isValid = false;
        //     return;
        // }
        // this.isValid = this.mat.MatNom != null && this.mat.MatNom != '';
      }
      //#endregion
    } 
})
</script>
